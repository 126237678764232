import React, { Suspense, useEffect, useRef } from 'react';
import './App.css';
import './Components/Shared/CustomInputField/CustomInputField.css';

import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { useThemeColor } from './hooks/useThemeColor';
import { useStatics } from './hooks/useStatics';

import { fetchLoginUserData, userDetail } from './Redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';

import { setInnerLoaderFalse, setInnerLoaderTrue } from './Redux/loaderSlice';

import { routesListAuth, routesListWithBackButtonPageName, exceptionalPages } from './Data/RoutingsData';

// custom-sweet-alert
import CustomSweetAlert2 from './Components/Shared/CustomSweetAlert/CustomSweetAlert2';
import SuccessDetails from './Components/Shared/DetailsModals/SuccessDetails/SuccessDetails';

import { AnimatePresence, motion } from "framer-motion";

// layouts
import BaseLayout from './Layouts/BaseLayout/BaseLayout';
import LayoutWithBackButtonPageName from './Layouts/LayoutWithBackButtonPageName/LayoutWithBackButtonPageName';
import { useSliderCardMenu } from './hooks/useSliderCardMenu';
import DynamicGames from './Pages/GamePages/DynamicGames/DynamicGames';
import PrivateRoute from './Pages/PrivateRoute/PrivateRoute';
import PublicRoute from './Pages/PrivateRoute/PublicRoute';
import Launcher from './Pages/GamePages/Launcher/Launcher';

import PlTechLancher from './Pages/GamePages/Launcher/PlTechLancher';

import Promotionals from './Pages/Shareables/Promotionals';

import LotteryBaseLayout from './Layouts/BaseLayout/LotteryBaseLayout';
import LotteryLayoutWithBackButtonPageName from './Layouts/LayoutWithBackButtonPageName/LotteryLayoutWithBackButtonPageName';
import LotteryLauncher from './Pages/GamePages/LotteryLauncher/LotteryLauncher';
import LotteryAbout from './Pages/GamePages/LotteryLauncher/LotteryAbout';
import LotteryResults from './Pages/GamePages/LotteryLauncher/LotteryResults';

// pages
const Home = React.lazy(() => import('./Pages/Home/Home'));

const Loader = React.lazy(() => import('./Components/Shared/Loader/Loader'));
const Progressbar = React.lazy(() => import('./Components/Shared/Progressbar/Progressbar'));
const CustomAlert = React.lazy(() => import('./Components/Shared/CustomAlert/CustomAlert'));
const MovableHome = React.lazy(() => import('./Components/Shared/MovableHome/MovableHome'));

const AgentsHome = React.lazy(() => import('./Pages/Agents/Home/AgentsHome'));

// export const rootUrl = process.env.REACT_APP_BASE_URL;
export const localUrl = `http://192.168.0.209:81/`;
export const serverUrl = `https://glo365.app`;
// export const rootUrl = localUrl;
export const rootUrl = serverUrl;
export const userKey = "gsuserkeys";

export const exchangeLaunch_site = "https://9imax.com/";

// export const origin = process.env.REACT_APP_BASE_ORIGIN;
export const RE_DIGIT = new RegExp(/^\d+$/);

function App() {

  const { appBG, promotionalsBG, progressBar, percentage, getStartRef } = useStatics();
  const { colorMode } = useThemeColor();

  // console.log(process.env.REACT_APP_BASE_URL);

  const user = useSelector(userDetail);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams,] = useSearchParams();

  const { sliderCardMenuData } = useSliderCardMenu();


  let parentUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;
  let url_val = parentUrl?.split('/');
  let currentRoute = url_val?.[3];

  // console.log(currentRoute, promotionalsBG);

  useEffect(() => {
    if (!user?.is_login) {
      const refFromParam = searchParams.get("ref");
      if (refFromParam) {
        localStorage.setItem("ref", refFromParam);
        // dispatch(setInnerLoaderTrue());
        navigate(currentRoute);
      }

      dispatch(setInnerLoaderFalse());
    }
    let stringValue = localStorage.getItem(userKey);
    if (stringValue !== null) {
      let value = JSON.parse(stringValue);

      localStorage.setItem(userKey, JSON.stringify({
        is_login: true,
        value: value.value
      }));
      dispatch(setInnerLoaderTrue());
      // dispatch(fetchLoginUserData());
      // navigate("/");
    }
    dispatch(setInnerLoaderFalse());
  }, [dispatch, user?.is_login, searchParams, navigate]);

  useEffect(() => {
    // dispatch(fetchGame());
    // dispatch(getAllGameRequest());
    dispatch(fetchLoginUserData());
    dispatch(setInnerLoaderFalse());
  }, [dispatch]);

  const installableBtnRef = useRef();

  useEffect(() => {
    sessionStorage.removeItem("isFirstLoad");
    const ready = (e) => {
      e.preventDefault();
      installableBtnRef.current = e;
    };

    window.addEventListener("beforeinstallprompt", ready);
    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  const avoidableRoutes = [
    `download_app_promo`,
    `launch`,
    `lottery-events`,
    `about`,
    `lottery-live-results`,
  ];

  return (
    <Suspense
      fallback={
        <Loader show={true} />
      }
    >
      <div className={"App row g-0 justify-content-center " + colorMode} style={{ backgroundColor: "", backgroundImage: `url(${(currentRoute === "download_app_promo") ? (rootUrl + promotionalsBG[0]?.image) : appBG && (rootUrl + appBG[0]?.image)})`, }}>
        {
          (
            percentage <= 100 &&
            progressBar
          ) ?
            <Progressbar percentage={percentage} />
            :
            <>
              <div className={(currentRoute === "download_app_promo") ? "" : "centered-main-section"} id="main">
                {/* <Loader /> */}
                {
                  (getStartRef && (!avoidableRoutes?.includes(currentRoute))) &&
                  <MovableHome />
                }
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0, x: "-50%" }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: "50%" }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  >
                    <Routes>
                      <Route index element={<BaseLayout><Home installableBtnRef={installableBtnRef} /></BaseLayout>} />

                      {
                        (routesListAuth?.map((item, index) =>
                          (item?.public === true) ?
                            <Route key={item?.id || index} path={item?.path} element={
                              <PublicRoute>
                                {item?.component}
                              </PublicRoute>
                            }
                            />
                            :
                            <Route key={item?.id || index} path={item?.path} element={
                              item?.component
                            }
                            />
                        ))
                      }

                      {
                        (routesListWithBackButtonPageName?.map((item, index) =>
                          (item?.private === true) ?
                            <Route key={item?.id || index} path={item?.path} element={
                              <PrivateRoute>
                                <LayoutWithBackButtonPageName pageTitle={item?.pageTitle}>
                                  {item?.component}
                                </LayoutWithBackButtonPageName>
                              </PrivateRoute>
                            } />
                            :
                            <Route key={item?.id || index} path={item?.path} element={<LayoutWithBackButtonPageName pageTitle={item?.pageTitle}>
                              {item?.component}
                            </LayoutWithBackButtonPageName>} />
                        ))
                      }

                      {/* {
                        (gamePages?.map((item, index) =>
                          <Route key={item?.id || index} path={item?.path} element={<LayoutWithBackButtonPageName pageTitle={item?.pageTitle}>
                            {item?.component}
                          </LayoutWithBackButtonPageName>} />
                        ))
                      } */}

                      {
                        (sliderCardMenuData?.map((item, index) =>
                          <Route key={item?.id || index} path={item?.page?.name} element={<LayoutWithBackButtonPageName pageTitle={item?.title}>
                            <DynamicGames currentStaticGameData={item} installableBtnRef={installableBtnRef} />
                          </LayoutWithBackButtonPageName>} />
                        ))
                      }
                      <Route path={"/chat"} element={
                        <>
                          <AgentsHome />
                        </>
                      }
                      />

                      {
                        (exceptionalPages?.map((item, index) =>
                          (item?.private === true) ?
                            <Route key={item?.id || index} path={item?.path} element={
                              <PrivateRoute>
                                <>
                                  {item?.component}
                                </>
                              </PrivateRoute>
                            } />
                            :
                            <Route key={item?.id || index} path={item?.path} element={<>
                              {item?.component}
                            </>} />
                        ))
                      }
                      {
                        // (percentage === 100 && !progressBar) &&
                        <Route path={"/launch/:p_code/:p_type/:g_code/:operator"} element={
                          <>
                            <Launcher />
                          </>
                        }
                        />
                      }
                      {
                        // (percentage === 100 && !progressBar) &&
                        <Route path={"/pltech-launcher/:p_code/:p_type/:g_code/:operator/:p_username/:p_password/:p_lang/:p_client/:p_mode/:p_game"} element={
                          <>
                            <PlTechLancher />
                          </>
                        }
                        />
                      }
                      {
                        <Route path={"/lottery-events/"} element={
                          <LotteryBaseLayout>
                            <LotteryLauncher />
                          </LotteryBaseLayout>
                        }
                        />
                      }
                      {
                        <Route path={"/about/:game_id"} element={
                          <PrivateRoute>
                            <LotteryLayoutWithBackButtonPageName pageTitle={`About`}>
                              <LotteryAbout />
                            </LotteryLayoutWithBackButtonPageName>
                          </PrivateRoute>
                        } />
                      }
                      {
                        <Route path={"/lottery-live-results/:p_code/:game_id"} element={
                          <PrivateRoute>
                            <LotteryLayoutWithBackButtonPageName pageTitle={`Results`}>
                              <LotteryResults />
                            </LotteryLayoutWithBackButtonPageName>
                          </PrivateRoute>
                        } />
                      }
                      <Route path={"/download_app_promo"} element={
                        <div>
                          <Promotionals installableBtnRef={installableBtnRef} />
                        </div>
                      }
                      />
                    </Routes>
                  </motion.div>
                </AnimatePresence>
              </div>
              <CustomAlert />
              <CustomSweetAlert2 />
              <SuccessDetails />
            </>
        }

      </div>
    </Suspense>
  );
}

export default App;
