import React, { useEffect } from "react";
// import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { userDetail } from "../../../Redux/userSlice";

const PlTechLauncher = () => {
    const {
        // p_code, p_type, g_code, operator, 
        p_username, p_password, p_lang, p_client, p_mode, p_game
    } = useParams();
    // const navigate = useNavigate();
    // const userInfo = useSelector(userDetail);

    // const [info, setInfo] = useState();

    // const baseDomain = userInfo?.user?.user_base_origin;

    // const [scriptLoaded, setScriptLoaded] = useState(false);

    // Log params for debugging
    // useEffect(() => {
    //     console.log(p_code, p_type, g_code, operator, p_username, p_password, p_lang, p_client, p_mode, p_game);
    // }, [p_code, p_type, g_code, operator, p_username, p_password, p_lang, p_client, p_mode, p_game]);

    // useEffect(() => {

    //     // Load the external JS file dynamically
    //     let script = document.createElement('script');
    //     script.src = 'https://login.fdgon88.com/jswrapper/flyingdragon88/integration.js';
    //     document.head.appendChild(script);

    //     script.onload = () => {
    //         window.iapiSetCallout('Login', calloutLogin);
    //         //

    //         window.iapiConf.clientUrl_ngm_desktop = 'https://cachedownload.fdgon88.com/ngmdesktop/casinoclient.html'
    //         window.iapiConf.clientUrl_ngm_mobile = 'https://m.fdgon88.com/casinomobile/casinoclient.html';
    //         window.iapiConf.clientUrl_live_desk = 'https://cachedownload.fdgon88.com/live/'
    //         window.iapiConf.clientUrl_live_mob = 'https://cachedownload.fdgon88.com/live/';

    //         setScriptLoaded(true); // Script has been successfully loaded
    //     };
    //     script.onerror = () => {
    //         console.error("Failed to load external script.");
    //     };
    //     document.head.appendChild(script);

    //     return () => {
    //         // Cleanup: remove script if component unmounts
    //         document.head.removeChild(script);
    //     };
    // }, []);

    const login = async () => {
        // if (!scriptLoaded) {
        //     console.error("Script not loaded yet.");
        //     return;
        // }

        const username = p_username || document.getElementById("username").value;
        const password = p_password || document.getElementById("password").value;
        const lang = p_lang || document.getElementById("lang").value;
        const mode = p_mode || document.getElementById("mode").value;
        const game = p_game || document.getElementById("game").value;
        const client = p_client || document.getElementById("client").value;

        let sessionToken;
        let url =
            "https://login.fdgon88.com/LoginAndGetTempToken.php?casinoname=flyingdragon88&realMode=EN&serviceType=GamePlay&systemId=77&clientType=casino&clientPlatform=web&clientSkin=flyingdragon88&languageCode=LoginService";
        if (mode === "real") {
            const formData = new FormData();
            formData.append("username", username);
            formData.append("password", password);
            try {
                const response = await fetch(url, {
                    method: "POST",
                    body: formData,
                });
                const data = await response.json();
                // console.log("Success:", data);
                sessionToken = data.sessionToken.sessionToken;
                // console.log("Session Token: ", sessionToken);
            } catch (error) {
                console.error("Error:", error);
            }
            launchGame(username, sessionToken, client, game, lang);
        } else {
            launchGameWithFunMode(username, sessionToken, client, game, lang);
        }
    };

    const launchGame = (username, sessionToken, client, game, lang) => {
        let mode = p_mode;
        let real = mode === "real" ? 1 : 0;
        let lobbyUrl = "";
        // let logoutUrl = "";
        // let supportUrl = "";
        let depositUrl = "";
        let platform =
            client === "ngm_desktop" || client === "live_desk" ? "web" : "mobile";
        let gameLauncherUrl = `https://login.fdgon88.com/GameLauncher?gameCodeName=${game}&username=${username}&tempToken=${sessionToken}&casino=flyingdragon88&clientPlatform=${platform}&language=${lang}&playMode=${real}&depositUrl=${depositUrl}&lobbyUrl=${lobbyUrl}`;
        window.location.href = gameLauncherUrl;
    };

    const launchGameWithFunMode = (
        username,
        sessionToken,
        client,
        game,
        lang
    ) => {
        if (client === "ngm_desktop" || client === "ngm_mobile") {
            let platform = client === "ngm_desktop" ? "web" : "mobile";
            let gameLauncherUrl = `https://login.fdgon88.com/GameLauncher?gameCodeName=${game}&username=${username}&tempToken=${sessionToken}&casino=flyingdragon88&clientPlatform=${platform}&language=${lang}&playMode=0&depositUrl=https://google.com&lobbyUrl=https://tools.ptdev.eu/cpsg/kade/technicalerror.html`;
            window.location.href = gameLauncherUrl;
        }
    };

    // const calloutLogin = (response) => {
    //     // console.log(response);
    //     if (response.errorCode) {
    //         if (response.errorCode === 48) {
    //             alert(`Login failed, error: ${response.errorCode} playerMessage: ${response.actions.PlayerActionShowMessage[0].message}`);
    //         } else {
    //             alert(`Login failed, error: ${response.errorCode} playerMessage: ${response.playerMessage}`);
    //         }
    //     } else {
    //         launchGame();
    //     }
    // };

    useEffect(() => {
        if (p_username && p_password && p_lang && p_client && p_mode && p_game) {
            login();
            // navigate("/");
        }
    }, [p_username, p_password, p_lang, p_client, p_mode, p_game]);

    return (
        <div className="d-none">
            <label htmlFor="username">Username:</label>
            <input type="text" id="username" name="username" placeholder="Must be capital letters" /><br /><br />
            <label htmlFor="password">Password:</label>
            <input type="text" id="password" name="password" /><br /><br />
            <label htmlFor="lang">Language:</label>
            <input type="text" id="lang" name="lang" placeholder="en,zh-CN,...." /><br /><br />
            <label htmlFor="client">Client:</label>
            <input type="text" id="client" name="client" placeholder="ngm_desktop,ngm_mobile,live_desk,live_mob" size="50" /><br /><br />
            <label htmlFor="mode">Mode:</label>
            <input type="text" id="mode" name="mode" placeholder="real,offline" /><br /><br />
            <label htmlFor="game">Game Code:</label>
            <input type="text" id="game" name="game" /><br /><br />
            <button id="PlTech" onClick={login}>Login and Launch</button>
        </div>
    );
};

export default PlTechLauncher;